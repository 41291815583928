import { render, staticRenderFns } from "./RagioneriaOspitiView.vue?vue&type=template&id=3b1bdd68&"
import script from "./RagioneriaOspitiView.vue?vue&type=script&lang=ts&"
export * from "./RagioneriaOspitiView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports