










import { Component, Vue } from "vue-property-decorator";
import OperatnNotFound from "@/components/gears/misc/OperatnNotFound.vue";

@Component({
  components: {
    OperatnNotFound,
  },
})
export default class RagioneriaOperatnNotFoundView extends Vue {}
