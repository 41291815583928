




import { Component, Vue } from "vue-property-decorator";

import OperatnBaseMenu, { MenuItem } from "@/components/gears/bases/OperatnBaseMenu.vue";

@Component({
  components: {
    OperatnBaseMenu,
  },
})
export default class MenuRagioneria extends Vue {
  /* DATA */

  private items: MenuItem[] = [
    {
      icon: "mdi-account-heart",
      text: "Quietanzianti",
      path: "/ragioneria/quietanzianti",
    },
    {
      icon: "mdi-circle-multiple",
      text: "Conti ricavi",
      path: "/ragioneria/conti-ricavi",
    },
    {
      icon: "mdi-account-box-multiple",
      text: "Tipi ospite",
      path: "/ragioneria/tipi-ospite",
    },
    {
      icon: "mdi-note-text-outline",
      text: "Tipi contratto",
      path: "/ragioneria/tipi-contratto",
    },
    {
      icon: "mdi-cash-multiple",
      text: "Tipi bolletta",
      path: "/ragioneria/tipi-bolletta",
    },
    {
      icon: "mdi-currency-usd",
      text: "Tariffe",
      path: "/ragioneria/tariffe",
    },
    {
      icon: "mdi-door-sliding",
      text: "Portinerie",
      path: "/ragioneria/portinerie",
    },
    {
      icon: "mdi-account-cash",
      text: "Contabilità",
      path: "/ragioneria/contabilita",
    },
    {
      icon: "mdi-file-document-edit-outline",
      text: "Contratti",
      path: "/ragioneria/contratti",
    },
    {
      icon: "mdi-account",
      text: "Ospiti",
      path: "/ragioneria/ospiti",
    },
  ];
}
