




import { Component, Vue } from "vue-property-decorator";
import Portinerie from "@/components/pages/portinerie/Portinerie.vue";

@Component({
  components: {
    Portinerie,
  },
})
export default class RagioneriaPortinerieView extends Vue {

}
